.fx--view {
  position: absolute;
  top: 200px;
  left: 50px;
}

.navigation--button__close {
  transition: opacity 0.75s ease-in, transform 0.4s ease-in;
  opacity: 0;
  transform: translateX(50px);
}

.navigation {
  transition: transform 0.35s;
  transform: translateX(100vw);
}

.navigation--list {
  transition: opacity 0.75s ease-in, transform 0.4s ease-in;
  opacity: 0;
  transform: translateX(50px);
}

.fx--navigation__open .navigation {
  transform: translateX(0);
}

.fx--navigation__open .navigation--button__close,
.fx--navigation__open .navigation--list {
  transform: translateX(0);
  opacity: 1;
}

.fx--home__line {
  display: block;
}

p.fx--home__line {
  color: #000 !important;
}

.navigation--overlay {
  transform: scale(0);
  transition: opacity 0.25s ease-in;
  opacity: 0.1;
}

.fx--navigation__open .navigation--overlay {
  transform: scale(1);
  opacity: 1;
}
